import React, { useContext, useEffect } from "react"
import { ContextDispatch } from "./../../context/Context"

import SEO from "../../components/seo/seo"

//Components
import Footer from "../../components/footer/footer"
import WhitepaperContent from "../../components/whitepaper-content/whitepaper-content"
import WhitepaperHero from "../../components/whitepaper-hero/whitepaper-hero"

const InsightsPage = props => {
  const page = props,
    templateData = page.data.contentfulWhitepaper

  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    const header = () => {
      storeDispatch({ type: "HEADER_LIGHT" })
    }
    header()
  }, [storeDispatch])

  return (
    <React.Fragment>
      {templateData.seoData && (
        <SEO
          title={templateData.seoData.seoTitle}
          image={templateData.seoData.seoImage}
          description={templateData.seoData.seoDescription}
        />
      )}

      <WhitepaperHero
        date={templateData.date}
        type={templateData.type}
        title={templateData.title}
        description={templateData.pageDescription.pageDescription}
        authorImage={templateData.authorImage}
        authorName={templateData.authorName}
        image={templateData.heroImage}
      />

      <WhitepaperContent sections={templateData.sections} />

      <Footer />
    </React.Fragment>
  )
}

export default InsightsPage

export const pageQuery = graphql`
  query whitepaperPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulWhitepaper(slug: { eq: $slug }) {
      ...whitepaperQuery
    }
  }
`
