import React from "react"

import ScrollSection from "../locomotiveScroll/scrollSection"
import Img from "gatsby-image"

import styles from "./whitepaper-hero.module.scss"

const WhitepaperHero = ({
  date,
  title,
  type,
  description,
  authorImage,
  authorName,
  image,
}) => {
  return (
    <div className={styles.heroWrapper}>
      <ScrollSection>
        <section className={styles.whitepaperHero}>
          {/* <div className={styles.heroImage}>
            <Img fluid={image.fluid} alt={image.description} />
          </div> */}

          <div className={styles.heroContent}>
            <p className={styles.heroDate}>
              {type ? `${type} / ` : ""}
              {date}
            </p>
            <h1 className={styles.heroTitle}>{title}</h1>

            {authorImage && authorName ? (
              <div className={styles.heroAuthor}>
                <div className={styles.heroAuthorImage}>
                  <Img
                    fluid={authorImage.fluid}
                    alt={authorImage.description}
                  />
                </div>

                <p className={styles.heroAuthorName}>{authorName}</p>
              </div>
            ) : (
              <p className={styles.heroDescription}>{description}</p>
            )}
          </div>
        </section>
      </ScrollSection>
    </div>
  )
}

export default WhitepaperHero
